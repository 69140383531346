<script lang="ts">
    import { writable } from "svelte/store";
    import LoaderBig from "./components/LoaderBig.svelte";
    import {createLoadingContext} from "./stores/loading"
    const AppModule = import(`./App.svelte`)//.then(async (module) => {await delay(10000); return module})

    const loadingModule = writable(true)
    AppModule.then(() => {
        loadingModule.set(false)
    })

    const loadingStore = createLoadingContext(loadingModule)
    loadingStore.needPageReady()

    let hideLoader = false
    $:if(!$loadingStore.firstLoading) hideLoader = true

    
</script>

{#await AppModule}
    <div>Loading...</div>
{:then module}
    <svelte:component this={module.default}/>
{:catch error}
    <div>Error: {error.message}</div>
{/await}

{#if $loadingStore.firstLoading}
    <div class="cover">
        <LoaderBig/>
    </div>
{/if}

<style>
    .cover {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
</style>
