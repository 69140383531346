import './app.css'
import App from './Main.svelte'

import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing"


if(import.meta.env.PROD){
  Sentry.init({
    dsn: "https://fb3c93148f5d4a799ff11dd10277b19d@o277959.ingest.sentry.io/6477664",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const app = new App({
  target: document.getElementById('app'),
})

export default app
