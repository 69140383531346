import { getContext, setContext } from 'svelte';
import {get, writable, type Readable} from 'svelte/store'
import { derived } from 'svelte/store';

export type LoadingStoreType = {
    loading: boolean
    firstLoading: boolean
}

export type LoadingStore = Readable<LoadingStoreType> & {
    addBackgroundTask: (store: Readable<boolean>, comment?:string) => void
    needPageReady: () => void
    pageReady: () => void
}



function createLoadingStore(initstore?: Readable<boolean>) : LoadingStore {
    const pending = writable(0)
    const firstLoading = writable(true)
    const pageReady = writable(false)
    const needPageReady = writable(false)
    

    function addBackgroundTask (store: Readable<boolean>, comment?:string) : void {
        if(!get(store)) {
            return
        }

        //console.log("loading store:","add:",comment)
        pending.update(v => v + 1)
        
        const un = store.subscribe((v) => {
            if(!v) {
                //console.log("loading store:","done:",comment)
                pending.update(v => v - 1)
                un()
            }
        })
    }

    if(initstore) {
        addBackgroundTask(initstore, "init")
    }


    const pageLoading = derived([needPageReady,pageReady], ([$needPageReady,$pageReady]) => $needPageReady && !$pageReady)
    const loading = derived(([pending,pageLoading]), ([$pending,$pageLoading]) => $pageLoading || $pending > 0 )

    loading.subscribe((v) => {
        //console.log("loading store:","loading:",v)

        if(!v) {
            firstLoading.set(false)
        }
    })

    const state = derived([loading, firstLoading], ([$loading, $firstLoading]) => ({firstLoading:$firstLoading, loading:$loading }))
/*
    pending.subscribe((v) => {
        console.log("loading store:","pending:",v)
    })

    firstLoading.subscribe((v) => {
        console.log("loading store:","firstLoading:",v)
    })
    
    pageReady.subscribe((v) => {
        console.log("loading store:","pageReady:",v)
    })
    
    loading.subscribe((v) => {
        console.log("loading store:","loading:",v)
    })
*/

    return {
        subscribe: state.subscribe,
        addBackgroundTask,
        needPageReady: () => needPageReady.set(true),
        pageReady: () => {
            //console.log("loading store:","pageReady")
            pageReady.set(true)
        }
    }
}


const loadingStoreSymbol = Symbol("loadingStore")

export function getLoadingStore() : LoadingStore {
    const context = getContext<LoadingStore>(loadingStoreSymbol)
    if(!context) {
        throw new Error("loading store not found")
    }
    return context
}

export function createLoadingContext(initstore?: Readable<boolean>) : LoadingStore {
    const store = createLoadingStore(initstore)
    setContext(loadingStoreSymbol, store)
    return store
}